/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Textarea } from 'theme-ui';
import { useState, useEffect } from 'react';
import Modal, { Props } from './Modal';
import Button from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { submittingFlagging } from 'reducers/flagging/actions';

interface CustomProps extends Props {
  comment?: string;
  content?: string;
  submitting?: boolean;
  type?: string;
  onConfirm: () => any;
  onCancel: () => any;
}

const InitialValues = {
  content: '',
  purpose: 'Report',
  type: '',
};

export default function ModalFlagPost({
  submitting,
  onConfirm,
  type = 'user',
  ...props
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    ...InitialValues,
    type,
  });

  useEffect(() => {
    setFormValues({
      ...InitialValues,
    });
  }, []);

  const handleSubmit = () => {
    submittingFlagging({
      ...formValues,
      type,
    })(dispatch).then((res: any) => {
      if (res) {
        if (onConfirm) onConfirm();
      }
    });
  };

  const handleChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  return (
    <Modal {...props} title="Flag Post">
      {t('Why are you flagging the post?')}

      <Textarea
        name="content"
        mt="2"
        placeholder="Enter your comment here"
        onChange={handleChange}
      />
      <div sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button variant="outline" onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          disabled={formValues?.content?.length < 3}
          sx={{ ml: 3 }}
          loading={submitting}
          onClick={handleSubmit}
        >
          {t('Flag')}
        </Button>
      </div>
    </Modal>
  );
}
