/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Button } from 'theme-ui';
import { ReactElement, ReactNode } from 'react';
import Link from 'next/link';
import LoadingSkeleton from 'components/common/LoadingSkeleton';
interface Props {
  children: ReactNode;
  slug?: string;
  small?: boolean;
}

const CategoryLabel = ({ children, slug = '', small }: Props): ReactElement =>
  slug && (
    <Link href={`/categories/${slug}`}>
      {slug && children ? (
        <Button
          sx={{
            py: 1,

            fontSize: small ? '0.6rem' : 1,
            fontWeight: 300,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Button>
      ) : (
        <LoadingSkeleton width="90px" height="25px" />
      )}
    </Link>
  );

export default CategoryLabel;
