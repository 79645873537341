/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import {
  usePopoverState,
  Popover,
  PopoverDisclosure,
  PopoverArrow,
} from 'reakit/Popover';
import Button from 'components/common/Button';
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from 'react-icons/fa';
import { AiOutlineLink, AiOutlineWhatsApp } from 'react-icons/ai';
import { IoIosShareAlt } from 'react-icons/io';
import getPlatformUrl from 'helpers/getPlatformUrl';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import LoadingSkeleton from 'components/common/LoadingSkeleton';

interface SharePostProps {
  post?: {
    slug?: string;
    title?: string;
  };
}

interface ShareButtonProps {
  icon?: any;
  children?: any;
  onClick?: any;
}

const ShareButton = ({ icon, children, onClick }: ShareButtonProps) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        px: '10px',
        mx: '5px',
        py: 3,
        '&:hover': {
          opacity: 0.8,
        },
        display: 'inline-flex',
        alignItems: 'center',
        color: 'textWhite',
      }}
    >
      {icon}
      {children}
    </Button>
  );
};

interface SocialLinkProps {
  type: 'facebook' | 'whatsapp' | 'twitter' | 'linkedin';
  children: any;
  title: string;
  link: string;
}

const SocialLink = ({ type, title, link, children }: SocialLinkProps) => {
  const t = {
    facebook: `https://www.facebook.com/sharer/sharer.php?${qs.stringify({
      display: 'page',
      u: link,
      quote: title,
    })}`,
    whatsapp: `https://api.whatsapp.com/send?${qs.stringify({
      text: `${title} ${link}`,
    })}`,
    twitter: `http://twitter.com/share?${qs.stringify({
      text: `${title} ${link}`,
    })}`,
    linkedin: ` https://www.linkedin.com/share?${qs.stringify({
      text: `${title} ${link}`,
    })}`,
  };

  return (
    <a href={t[type]} target="_blank" rel="noreferrer noopener">
      {children}
    </a>
  );
};

function SharePost({ post }: SharePostProps) {
  const popover = usePopoverState({ placement: 'top-start' });
  const { title } = post;
  const { t } = useTranslation();
  const link = `${getPlatformUrl()}/posts/${post?.slug}`;
  return (
    <>
      <PopoverDisclosure
        {...popover}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          fontSize: '1rem',
          cursor: 'pointer',
          color: 'rgba(0, 0, 0, 0.3)',
          padding: '0px',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {post?.slug ? (
          <>
            <IoIosShareAlt color="rgba(0, 0, 0, 0.2)" size="25" />
          </>
        ) : (
          <LoadingSkeleton />
        )}
      </PopoverDisclosure>
      <Popover {...popover} aria-label="Share">
        <div
          sx={{
            display: 'flex',
            bg: 'textBlack',
            boxShadow: '0px 0px 1px 1px rgba(0,0,0,0.09)',
            color: 'textWhite',
            mb: '-5px',
            borderRadius: 2,
          }}
        >
          <PopoverArrow {...popover} />
          <ShareButton
            icon={<AiOutlineLink />}
            onClick={() => {
              navigator.clipboard.writeText(link);
              toast.success(`${t('Copied')} "${link}"!`);
            }}
          />
          <SocialLink link={link} title={title} type="facebook">
            <ShareButton icon={<FaFacebookSquare />} />
          </SocialLink>
          <SocialLink link={link} title={title} type="whatsapp">
            <ShareButton icon={<AiOutlineWhatsApp />} />
          </SocialLink>
          <SocialLink link={link} title={title} type="twitter">
            <ShareButton icon={<FaTwitterSquare />} />
          </SocialLink>
          <SocialLink link={link} title={title} type="linkedin">
            <ShareButton icon={<FaLinkedin />} />
          </SocialLink>
        </div>
      </Popover>
    </>
  );
}

export default SharePost;
