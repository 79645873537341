/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import dayjs from '../../helpers/dayjs';
import { useRouter } from 'next/router';

import Link from 'next/link';
import { ReactElement } from 'react';
import CategoryLabel from '../CategoryLabel';
import LoadingSkeleton from 'components/common/LoadingSkeleton';
import DefaultAvatar from 'assets/svg/default-avatar.svg';
interface Props {
  user?: {
    firstName: string;
    lastName: string;
    avatarUrl?: string;
    username: string;
  };
  submittedDate?: Date;
  categoryName?: string;
  categorySlug?: string;
  sidebar?: boolean;
}

const PostCardHeader = ({
  user,
  submittedDate,
  categoryName,
  categorySlug,
  sidebar,
}: Props): ReactElement => {
  const { locale } = useRouter();

  return (
    <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
      <Link href={`/authors/${user?.username}`}>
        <Flex sx={{ cursor: 'pointer' }}>
          {user?.firstName ? (
            <img
              sx={{
                height: '50px',
                width: '50px',
                objectFit: 'cover',
                borderRadius: '50%',
                boxShadow: '0px 1px 1px 0 rgba(0,0,0, 0.09)',
              }}
              src={user?.avatarUrl || DefaultAvatar}
              alt="profile picture"
            />
          ) : (
            <LoadingSkeleton width="50px" height="50px" circle={true} />
          )}{' '}
          <Flex sx={{ flexDirection: 'column', px: 2 }}>
            <div
              sx={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                color: 'primary',
              }}
            >
              {user?.firstName || <LoadingSkeleton width="70px" />}{' '}
              {user?.lastName}
            </div>
            <div sx={{ color: 'gray', fontSize: 1 }}>
              {submittedDate ? (
                dayjs(submittedDate).locale(locale).fromNow()
              ) : (
                <LoadingSkeleton width="50px" />
              )}
            </div>
          </Flex>
        </Flex>
      </Link>

      <CategoryLabel small={sidebar} slug={categorySlug}>
        {categoryName}
      </CategoryLabel>
    </Flex>
  );
};

export default PostCardHeader;
