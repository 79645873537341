import { toast } from 'react-toastify';
import http from '../../../helpers/http';
import * as types from './types';
import {
  addBookmark,
  removeBookmark,
  addLike,
  removeLike,
} from '../currentUser/actions';

import { deleteCurrentPost } from '../author/actions';

export const setLoading = (payload) => ({
  type: types.SET_LOADING,
  payload,
});

export const setSubmitting = (payload) => ({
  type: types.SET_SUBMITTING,
  payload,
});

export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});

export const setPost = (payload) => ({
  type: types.SET_POST,
  payload,
});

export const updateBookmarksCount = (payload) => ({
  type: types.UPDATE_BOOKMARKS_COUNT,
  payload,
});

export const updateLikesCount = (payload) => ({
  type: types.UPDATE_LIKES_COUNT,
  payload,
});

export const updateDisLikesCount = (payload) => ({
  type: types.UPDATE_DISLIKES_COUNT,
  payload,
});

export const setFeed = (payload) => ({
  type: types.SET_FEED,
  payload,
});

export const deletePost = (payload) => ({
  type: types.DELETE_POST,
  payload,
});

export const fetchPosts =
  ({ page }: { page?: number }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await http.get(`/posts`);
      dispatch(setPost(data));
      return data;
    } catch (err) {
      // const { data } = err.response;
      dispatch(setPost({}));
    }
  };

export const fetchPost =
  ({ slug = '' }: { slug: any }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await http.get(`/posts/${slug}`);
      dispatch(setPost(data.post));
      return data;
    } catch (err) {
      dispatch(setPost({}));
      return err?.response?.data;
    }
  };

export const submitPost =
  ({ payload }: { slug?: any; payload: any }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        const value = payload[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(`${key}[]`, item);
          });

          if (value.length === 0) {
            formData.append(`${key}[]`, null);
          }
        } else {
          formData.append(key, value);
        }
      });

      const { data } = await http.post(`/posts`, formData);

      toast(data.message);

      // dispatch(setPost(data.post));
      return data;
    } catch (err) {
      // const { data } = err.response;
      dispatch(setPost({}));
    }
  };

export const updatePost =
  ({ payload, slug }: { slug?: any; payload: any }) =>
  async (dispatch) => {
    try {
      dispatch(setSubmitting(true));
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        const value = payload[key];
        if (Array.isArray(value)) {
          value.forEach((item) => {
            formData.append(`${key}[]`, item);
          });

          if (value.length === 0) {
            formData.append(`${key}[]`, null);
          }
        } else {
          formData.append(key, value);
        }
      });

      const { data } = await http.put(`/posts/${slug}`, formData);

      toast(data.message);

      // dispatch(setPost(data.post));
      return data;
    } catch (err) {
      // const { data } = err.response;
      dispatch(setSubmitting(false));
    }
  };

export const submitDeletePost =
  ({ slug }: { slug?: any }) =>
  async (dispatch) => {
    try {
      dispatch(setSubmitting(true));

      const { data } = await http.delete(`/posts/${slug}`);

      toast(data.message);

      const post = { slug };
      dispatch(deletePost(post));
      dispatch(deleteCurrentPost(post));
      return data;
    } catch (err) {
      // const { data } = err.response;
      dispatch(setSubmitting(false));
    }
  };

export const bookmarkPost =
  ({
    slug,
    bookmarked = false,
    payload,
  }: {
    slug?: string;
    bookmarked?: boolean;
    payload: any;
  }) =>
  async (dispatch) => {
    try {
      if (bookmarked) {
        dispatch(removeBookmark(payload));
      } else {
        dispatch(addBookmark(payload));
      }
      dispatch(updateBookmarksCount({ count: bookmarked ? -1 : 1, slug }));
      const { data } = await http[`${bookmarked ? 'delete' : 'post'}`](
        `/posts/${slug}/bookmarks`,
      );

      return data;
    } catch (err) {
      // const { data } = err.response;
      dispatch(updateBookmarksCount({ count: !bookmarked ? -1 : 1, slug }));
      if (!bookmarked) {
        dispatch(removeBookmark(payload));
      } else {
        dispatch(addBookmark(payload));
      }
    }
  };

export const likePost =
  ({
    slug,
    liked = false,
    payload,
    type = 'like',
  }: {
    slug?: string;
    liked?: boolean;
    type?: 'like' | 'dislike';
    payload: any;
  }) =>
  async (dispatch) => {
    try {
      if (liked) {
        dispatch(removeLike(payload));
      } else {
        dispatch(addLike(payload));
      }
      const { data } = await http[`${liked ? 'delete' : 'post'}`](
        `/posts/${slug}/likes?type=${type}`,
      );

      const counter = { count: liked ? -1 : 1, slug };
      if (type === 'like') {
        dispatch(updateLikesCount(counter));
      } else {
        dispatch(updateDisLikesCount(counter));
      }

      return data;
    } catch (err) {
      // const { data } = err.response;
      const counter = { count: !liked ? -1 : 1, slug };
      if (type === 'like') {
        dispatch(updateLikesCount(counter));
      } else {
        dispatch(updateDisLikesCount(counter));
      }
      if (!liked) {
        dispatch(removeLike(payload));
      } else {
        dispatch(addLike(payload));
      }
    }
  };

export const fetchFeed = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/posts/feed');

    dispatch(setFeed(data.feed));
  } catch (err) {
    dispatch(setFeed([]));
  }
};
