/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Flex } from 'theme-ui';
import { ReactElement, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  IoThumbsUpSharp,
  IoThumbsDownSharp,
  IoFlagSharp,
  IoEyeSharp,
} from 'react-icons/io5';
import Button from 'components/common/Button';
import { FaComment, FaBookmark } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  bookmarkPost,
  likePost,
  submitDeletePost,
} from 'reducers/post/actions';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import { setAuthModal } from 'reducers/auth/actions';
import {
  selectCurrentUser,
  selectBookmarked,
  selectLiked,
  selectDisLiked,
} from 'reducers/currentUser/selectors';
import { useRouter } from 'next/router';
import LoadingSkeleton from 'components/common/LoadingSkeleton';
import ModalConfirm from 'components/common/ModalConfirm';
import ModalFlagPost from 'components/common/ModalFlagPost';

import SharePost from './SharePost';

const Action = ({
  icon,
  count,
  mr = 4,
  ml = 0,
  active = false,
  onClick,
  children,
  loading,
  submitting = false,
}: {
  icon?: any;
  count?: number;
  mr?: number;
  ml?: number;
  active?: boolean;
  onClick?: () => void;
  children?: string;
  loading?: boolean;
  submitting?: boolean;
}): ReactElement => (
  <Button
    variant="text"
    sx={{
      mr,
      ml,
      display: 'inline-flex',
      alignItems: 'center',
      color: active ? 'textGreen' : 'textGrayLight',
      fontSize: 3,
      '&:hover': onClick
        ? {
            cursor: 'pointer',
            color: 'textGreen',
          }
        : {},
      p: 1,
      pt: '2px',
      pb: 0,
    }}
    onClick={onClick}
    loading={submitting}
  >
    {loading ? (
      <LoadingSkeleton width="30px" />
    ) : (
      <>
        {icon}
        {count > 0 ? (
          <span sx={{ ml: 1, display: 'inline-block' }}>{count}</span>
        ) : null}
        {children && <span sx={{ fontSize: 2, mt: '4px' }}>{children}</span>}
      </>
    )}
  </Button>
);

interface Props {
  commentsCount?: number;
  commentsActive?: boolean;
  likesCount?: number;
  likesActive?: boolean;
  disLikesCount?: number;
  dislikesActive?: boolean;
  bookmarkActive?: boolean;
  onClick?({ type }: { type: string }): void;
  post?: {
    commentsCount?: number;
    commentsActive?: boolean;
    likesCount?: number;
    likesActive?: boolean;
    disLikesCount?: number;
    dislikesActive?: boolean;
    bookmarkActive?: boolean;
    slug?: string;
    id?: string;
    title?: string;
    flagged?: boolean;
    userId?: string;
    viewsCount?: number;
  };
  showDelete?: boolean;
}

export default function PostActions({ onClick = () => '', post = {} }: Props) {
  const [deleting, setDeleting] = useState(false);
  const { push } = useRouter();
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selectCurrentUser);
  const bookmarks = useSelector(selectBookmarked);
  const likes = useSelector(selectLiked);
  const disLikes = useSelector(selectDisLiked);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFlag, setOpenFlag] = useState(false);
  const popover = usePopoverState({ placement: 'auto-start' });

  const {
    commentsCount = 0,
    commentsActive,
    likesCount = 0,
    disLikesCount = 0,
    viewsCount = 0,
    slug,
    id,
    flagged,
  } = post;

  const handleDeletePost = () => {
    setDeleting(true);
    submitDeletePost({
      slug,
    })(dispatch)
      .then((res) => {
        if (res?.status === 200) {
          window.location.href = '/';
        }
      })
      .finally(() => setDeleting(false));
  };

  const handleClick = (e: { type: string }) => {
    if (onClick) {
      onClick(e);
    }

    if (!currentUser?.firstName) {
      dispatch(setAuthModal('Login'));
      return false;
    }

    switch (e.type) {
      case 'like':
        likePost({
          type: 'like',
          slug,
          liked: !!likes[id],
          payload: {
            createdAt: new Date(),
            postId: id,
            userId: currentUser?.id,
          },
        })(dispatch);
        break;
      case 'dislike':
        likePost({
          type: 'dislike',
          slug,
          liked: !!likes[id],
          payload: {
            createdAt: new Date(),
            postId: id,
            userId: currentUser?.id,
          },
        })(dispatch);
        break;
      case 'bookmark':
        bookmarkPost({
          slug,
          bookmarked: !!bookmarks[id],
          payload: {
            createdAt: new Date(),
            postId: id,
            userId: currentUser?.id,
          },
        })(dispatch);
        break;
      case 'delete':
        setOpenDelete(true);
        break;
      case 'flag':
        setOpenFlag(true);
        break;
      case 'comment':
        push(`/posts/${slug}#post-comments-title`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ModalFlagPost
        open={openFlag}
        onClose={() => setOpenFlag(false)}
        onCancel={() => setOpenFlag(false)}
        onConfirm={() => setOpenFlag(false)}
      />

      <ModalConfirm
        onConfirm={handleDeletePost}
        submitting={deleting}
        title="Delete Post"
        comment="Do you want to delete the post?"
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onCancel={() => setOpenDelete(false)}
      />

      <Flex sx={{ justifyContent: 'space-between', mt: 2 }}>
        <Flex>
          {viewsCount > 0 && (
            <Action icon={<IoEyeSharp />} count={viewsCount} />
          )}

          <Action
            onClick={() => handleClick({ type: 'like' })}
            icon={<IoThumbsUpSharp />}
            count={likesCount}
            active={likes[id]}
            loading={!post?.id}
          />
          <Action
            onClick={() => handleClick({ type: 'dislike' })}
            icon={<IoThumbsDownSharp />}
            count={disLikesCount}
            active={disLikes[id]}
            loading={!post?.id}
          />
          <Action
            onClick={() => handleClick({ type: 'comment' })}
            icon={<FaComment />}
            count={commentsCount}
            active={commentsActive}
            loading={!post?.id}
          />
          <SharePost post={post} />
        </Flex>

        <>
          <PopoverDisclosure
            {...popover}
            style={{
              background: 'none',
              border: 'none',
              display: 'inline-flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            {post?.id ? <BsThreeDotsVertical /> : <LoadingSkeleton />}
          </PopoverDisclosure>
          <Popover {...popover} tabIndex={0} aria-label="more-actions">
            <Flex
              style={{
                backgroundColor: 'white',
                boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.2)',
                padding: '5px 10px',
              }}
            >
              <Action
                onClick={() => handleClick({ type: 'bookmark' })}
                icon={<FaBookmark />}
                active={bookmarks[id]}
                loading={!post?.id}
              />
              <Action
                onClick={() => handleClick({ type: 'flag' })}
                icon={<IoFlagSharp size="25" />}
                mr={0}
                active={flagged}
                loading={!post?.id}
              />
              {currentUser.id && currentUser.id == post.userId && (
                <Action
                  onClick={() => handleClick({ type: 'delete' })}
                  icon={<AiFillDelete size="25" />}
                  mr={0}
                  ml={3}
                  submitting={deleting}
                />
              )}
            </Flex>
          </Popover>
        </>
      </Flex>
    </>
  );
}
