/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useTranslation } from 'react-i18next';
import Modal, { Props } from './Modal';
import Button from 'components/common/Button';

interface CustomProps extends Props {
  comment?: string;
  content?: string;
  submitting?: boolean;
  onConfirm: () => any;
  onCancel: () => any;
}

export default function ModalConfirm({
  comment,
  content,
  submitting,
  onConfirm,
  ...props
}: CustomProps) {
  const { t } = useTranslation();
  return (
    <Modal {...props}>
      {comment && (
        <p sx={{ mb: '15px', fontSize: 3, fontWeight: 300 }}>{comment}</p>
      )}

      {content && (
        <p sx={{ mb: '15px', fontSize: 2, color: 'red' }}>"{content}"</p>
      )}

      <div sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button variant="outline" onClick={props.onCancel}>
          {t('Cancel')}
        </Button>
        <Button loading={submitting} onClick={onConfirm}>
          {t('Yes')}
        </Button>
      </div>
    </Modal>
  );
}
