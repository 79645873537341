import React from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props {
  loading?: boolean;
  width?: string;
  height?: string;
  count?: number;
  circle?: boolean;
  style?: any;
}

const LoadingSkeleton = ({ loading, ...props }: Props) =>
  loading ? null : <Skeleton {...props} />;

export default LoadingSkeleton;
