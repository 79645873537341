/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, Grid } from 'theme-ui';
import { ReactElement } from 'react';
import Link from 'next/link';
import LoadingSkeleton from 'components/common/LoadingSkeleton';

interface Props {
  title: string;
  content: string;
  coverImageUrl?: string;
  slug?: string;
  sidebar?: boolean;
  readmoreText?: string;
}

const PostCardBody = ({
  title,
  readmoreText,
  slug,
  content,
  coverImageUrl,
  sidebar,
}: Props): ReactElement => {
  return (
    <Link href={`/posts/${slug}`}>
      <Grid columns={[1, slug && coverImageUrl ? '1.5fr 1fr' : 1]}>
        <div
          sx={{
            gridRow: [2, 1],
            mr: [0, 1],
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <h2
            sx={{
              fontWeight: sidebar ? 300 : 400,
              fontSize: sidebar ? 2 : 4,
              mt: 0,
              lineHeight: 1.2,
            }}
          >
            {title || <LoadingSkeleton width="90px" />}
          </h2>
          {!sidebar && (
            <>
              {content || title ? (
                <div
                  sx={{
                    display: '-webkit-box',
                    WebkitLineClamp: sidebar ? 2 : 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    p: {
                      m: 0,
                      p: 0,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: (content ? `${content} ...` : '').replace(
                      /(<([^>]+)>)/gi,
                      '',
                    ),
                  }}
                />
              ) : (
                <LoadingSkeleton count={3} />
              )}

              <div sx={{ mt: 2, textDecoration: 'underline' }}>
                {title ? readmoreText : <LoadingSkeleton width="70px" />}
              </div>
            </>
          )}
        </div>

        {coverImageUrl && (
          <div
            sx={{
              height: ['30vh', 'auto'],
              overflow: 'hidden',
              background: coverImageUrl ? `url("${coverImageUrl}")` : 'none',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              borderRadius: 5,
              minHeight: ['80px'],
              ml: [0, 1],
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {!coverImageUrl && !title && (
              <LoadingSkeleton width="100%" height="100%" />
            )}
          </div>
        )}
      </Grid>
    </Link>
  );
};

export default PostCardBody;
