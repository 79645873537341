import http from '../../../helpers/http';
import * as types from './types';

export const setLoading = (payload) => ({
  type: types.SET_LOADING,
  payload,
});

export const setError = (payload) => ({
  type: types.SET_ERROR,
  payload,
});

export const setFlagging = (payload) => ({
  type: types.SET_FLAGGING,
  payload,
});

export const fetchFlagging = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { data } = await http.get('/flagging');

    dispatch(setFlagging(data.flagging));
  } catch (err) {
    // const { data } = err.response;
    dispatch(setFlagging([]));
  }
};

export const submittingFlagging =
  ({
    purpose = 'Report',
    ...payload
  }: {
    type: string;
    purpose: string;
    postId?: string;
    userId?: string;
    content?: string;
  }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const { data } = await http.post(`/flagging`, {
        ...payload,
        purpose,
      });
      dispatch(setFlagging(data));
      return data;
    } catch (err) {
      // const { data } = err.response;
    }
  };
