/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useRef } from 'react';
import ContentLoader from 'react-content-loader';
import PostCardHeader from './PostCardHeader';
import PostCardBody from './PostCardBody';
import PostCardActions from '../common/PostActions';
import { useTranslation } from 'react-i18next';

export const PostCardLoader = (props) => {
  const containerRef = useRef(null);
  const width = containerRef.current
    ? containerRef?.current?.offsetWidth - 10
    : 500;

  return (
    <div
      sx={{
        display: 'flex',
        width: '100%',
        variant: 'containers.box',
        px: 3,
        py: 2,
        overflow: 'hidden',
        bg: 'white',
      }}
      ref={containerRef}
    >
      <ContentLoader
        speed={2}
        width={width}
        height="150"
        viewBox={`0 0 ${width} 150`}
        backgroundColor="#eeeeee"
        foregroundColor="#d1d1d1"
        {...props}
      >
        <rect x="0" y="0" rx="50" ry="500" width="50" height="50" />
        <rect x="60" y="10" rx="3" ry="3" width="100" height="10" />
        <rect x="60" y="30" rx="3" ry="3" width="70" height="10" />

        <rect x={width - 90} y="15" rx="3" ry="3" width="70" height="20" />

        <rect x="0" y="65" rx="3" ry="3" width="200" height="10" />

        <rect x="0" y="90" rx="3" ry="3" width="250" height="5" />
        <rect x="0" y="105" rx="3" ry="3" width="300" height="5" />
        <rect x="0" y="120" rx="3" ry="3" width="300" height="5" />

        <rect x={width - 100} y="50" rx="3" ry="3" width="80" height="80" />
      </ContentLoader>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    variant: 'containers.box',
    justifyContent: 'space-between',
    p: 3,
    bg: 'white',
  },
};

interface Props {
  post?: {
    slug?: string;
    title?: string;
    content?: string;
    summary?: string;
    coverImageUrl?: string;
    submittedAt?: Date;
    createdAt?: Date;
    user?: {
      firstName: string;
      lastName: string;
      avatarUrl: string;
      username: string;
    };
    category?: {
      en: string;
      from: string;
      slug: string;
    };
  };
  sidebar?: boolean;
  showDelete?: boolean;
}

export default function PostCard({ post = {}, sidebar, showDelete }: Props) {
  const { t } = useTranslation();
  return (
    <div
      sx={{
        ...styles.container,
        boxShadow: sidebar ? 'none' : undefined,
        pl: sidebar ? '0px' : undefined,
        pr: sidebar ? '0px' : undefined,
        pt: sidebar ? '0px' : undefined,
      }}
    >
      <PostCardHeader
        user={post?.user}
        submittedDate={post?.submittedAt || post?.createdAt}
        categoryName={post?.category?.en}
        categorySlug={post?.category?.slug}
        sidebar={sidebar}
      />
      <PostCardBody
        slug={post?.slug}
        title={post?.title}
        content={post?.summary || post?.content}
        coverImageUrl={post?.coverImageUrl}
        sidebar={sidebar}
        readmoreText={t('Read more')}
      />
      {!sidebar && <PostCardActions post={post} showDelete={showDelete} />}
    </div>
  );
}
